import utils from "@/utilities/Utils";

export class ComparisonReportRequest {

    agreementNumber: number = 0;
    dropAccountNumber: number = 0;
    monthYear: Date|null = null;
    categoryID: number = 0;

    get isValid(): boolean {
        return !utils.isEmptyId(this.agreementNumber) && !utils.isEmptyId(this.dropAccountNumber) && this.monthYear != null 
    }

}