import { ComparisonProduct } from "@/model/ComparisonProduct";

export interface IComparisonSharedProduct {
    code: number;
    description: string;
    cases: number;
    splits: number;
    value: number;
    cases2: number;
    splits2: number;
    value2: number;
    difference: number;
}

export class ComparisonSharedProduct extends ComparisonProduct implements IComparisonSharedProduct {

    cases2: number = 0;
    splits2: number = 0;
    value2: number = 0;
    difference: number = 0;

}