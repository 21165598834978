
    import Vue from "vue";
    import Component from "vue-class-component";
    import { Watch } from "vue-property-decorator";
    import { ActiveElement, Chart, ChartEvent, registerables } from "chart.js";
    import apiClient from "@/utilities/ApiClient";
    import utils from "@/utilities/Utils";
    import { mapData } from "@/utilities/DataMapping";
    import { ComparisonReportRequest } from "@/model/ComparisonReportRequest";
    import { IComparisonReport, ComparisonReport as ComparisonReportModel } from "@/model/ComparisonReport";
    import { UserAgreement, UserAgreementCustomer } from "@/model/User";
    import { Lookup } from "@/model/Lookup";
    import Utils from "@/utilities/Utils";
    import PurchasesDialogue from '@/components/PurchasesDialogue.vue';
    import * as toastr from "toastr";

    @Component({ components: { PurchasesDialogue } })
    export default class ComparisonReport extends Vue {
        
        async mounted() {
            Chart.register(...registerables);
            await this.getAgreements();
            await this.getCategories();

            if (this.agreements.length == 1) {
                this.requestOne.agreementNumber = this.agreements[0].agreementNumber;
                this.requestTwo.agreementNumber = this.agreements[0].agreementNumber;
            }
        }

        //
        // properties
        //

        leftPickerConfig = { format: "MMMM yyyy", widgetPositioning: { horizontal: "right" } }
        rightPickerConfig = { format: "MMMM yyyy" }

        categories: Array<Lookup> = [];
        agreements: Array<UserAgreement> = [];
        customersOne: Array<UserAgreementCustomer> = [];
        customersTwo: Array<UserAgreementCustomer> = [];

        requestOne: ComparisonReportRequest = new ComparisonReportRequest();
        requestTwo: ComparisonReportRequest = new ComparisonReportRequest();

        reportOne: ComparisonReportModel = new ComparisonReportModel();
        reportTwo: ComparisonReportModel = new ComparisonReportModel();

        purchaseMixOneChart: any = undefined;
        purchaseMixTwoChart: any = undefined;

        //
        // computed
        //
        
        get availableCustomersOne(): Array<UserAgreementCustomer> {
            return this.customersOne.filter(c => c.isActive || this.$store.state.includeInactive);
        }

        get availableCustomersTwo(): Array<UserAgreementCustomer> {
            return this.customersTwo.filter(c => c.isActive || this.$store.state.includeInactive);
        }

        get comparisonVisible() {
            return this.requestOne.isValid && this.requestTwo.isValid;
        }

        get reportOneTitle() {
            const customers = this.customersOne.filter(c => c.dropAccount == this.requestOne.dropAccountNumber);
            return customers.length > 0 
                ? `${customers[0].customerName} - ${utils.monthYearText(this.requestOne.monthYear)}`
                : "";
        }
        
        get reportTwoTitle() {
            const customers = this.customersTwo.filter(c => c.dropAccount == this.requestTwo.dropAccountNumber);
            return customers.length > 0 
                ? `${customers[0].customerName} - ${utils.monthYearText(this.requestTwo.monthYear)}`
                : "";
        }

        get categoryOptions(): Array<Lookup> {
            const retVal: Array<Lookup> = [];
            retVal.push(new Lookup({ id: 0, guid: Utils.emptyGuidValue, description: "All Categories" }));
            retVal.push(...this.categories);
            return retVal;
        }

        get showCatMixPie(): boolean {
            return Utils.isEmptyId(this.requestOne.categoryID) && Utils.isEmptyId(this.requestTwo.categoryID);
        }

        //
        // watchers
        //

        @Watch("requestOne.agreementNumber")
        private async onAgreementOneChanged(value: number) {
            this.requestOne.dropAccountNumber = 0;
            this.requestOne.monthYear = null;
            await this.getCustomers(value, this.customersOne);
            await this.getDefaultDate(this.requestOne);
            await this.getReports();
        }

        @Watch("requestOne.dropAccountNumber")
        private async onDropAccountOneChanged() {
            await this.getDefaultDate(this.requestOne);
            await this.getReports();
        }

        @Watch("requestOne.monthYear")
        private async onMonthYearOneChanged() {
            await this.getReports();
        }
        
        @Watch("requestTwo.agreementNumber")
        private async onAgreementTwoChanged(value: number) {
            this.requestTwo.dropAccountNumber = 0;
            this.requestTwo.monthYear = null;
            await this.getCustomers(value, this.customersTwo);
            await this.getDefaultDate(this.requestTwo);
            await this.getReports();
        }

        @Watch("requestTwo.dropAccountNumber")
        private async onDropAccountTwoChanged() {
            await this.getDefaultDate(this.requestTwo);
            await this.getReports();
        }

        @Watch("requestTwo.monthYear")
        private async onMonthYearTwoChanged() {
            await this.getReports();
        }

        @Watch("requestOne.categoryID")
        private async onCatOneChanged(newVal: number) {
            this.requestTwo.categoryID = newVal;
            await this.getReports();      
        }

        // 
        // methods
        // 

        async getCategories() {
            const serverData = await apiClient.get("api/agreement/categories");
            this.categories = mapData<Array<Lookup>>(serverData, Lookup.mapping);
        }

        async getAgreements() {
            const response = await apiClient.get("api/agreement/agreements");
            this.agreements = mapData(response, UserAgreement.mapping);
        }

        async getCustomers(agreementNumber: number, lookupArray: Array<UserAgreementCustomer>) {
            lookupArray.length = 0;

            if (utils.isEmptyId(agreementNumber)) {
                return
            }

            const response = await apiClient.get(`api/agreement/customers?agreementNumber=${agreementNumber}`);
            lookupArray.push(...response.map((c: any) => new UserAgreementCustomer(c)));
        }

        async getSuppliers(agreementNumber: number, lookupArray: Array<Lookup>) {
            lookupArray.length = 0;

            if (utils.isEmptyId(agreementNumber)) {
                return;
            }

            const response = await apiClient.get(`api/agreement/suppliers?id=${agreementNumber}`);
            lookupArray.push(...response.map((s: any) => new Lookup(s)));
        }

        async getDefaultDate(request: ComparisonReportRequest) {
            if (utils.isEmptyId(request.agreementNumber) || utils.isEmptyId(request.dropAccountNumber)) {
                request.monthYear = null;
                return;
            }

            const dateString = await apiClient.get(`api/agreement/latestPurchaseInfoReceived?agreementNumber=${request.agreementNumber}&dropAccountNumber=${request.dropAccountNumber}`);
            request.monthYear = new Date(dateString);
        }

        async getReports() {
            await this.getReport(1);
            await this.getReport(2);
        }

        async getReport(reportNo: number) {
            const request: ComparisonReportRequest = reportNo == 1 ? this.requestOne : this.requestTwo, 
                  compareWith: ComparisonReportRequest = reportNo == 1 ? this.requestTwo : this.requestOne,
                  report: ComparisonReportModel = reportNo == 1 ? this.reportOne : this.reportTwo;

            if (!request.isValid) {
                utils.resetObject(report);
                return
            }

            const data = {
                compare: request,
                compareWith: compareWith
            }
            const response: IComparisonReport = await apiClient.post("api/agreement/comparisonReport", data);
            report.update(response);
            
            if (reportNo == 1) this.updateChartOne();
            if (reportNo == 2) this.updateChartTwo();
        }

        drawChartOne() {
            // get canvas dom element 
            const canvas = (this.$refs.purchaseMixOneChart as HTMLCanvasElement);
            if (canvas === undefined) return;

            // get context from canvas
            const ctx = canvas.getContext("2d");
            if (ctx === undefined) return;

            // clear chart if already exists
            if (this.purchaseMixOneChart !== undefined) {
                this.purchaseMixOneChart.destroy();
            }

            // create chart
            this.purchaseMixOneChart = new Chart(ctx!, {
                type: "doughnut",
                data: {
                    datasets: []
                },
                options: {
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: true,
                            position: "left"
                        },
                        tooltip: {
                            callbacks: {
                                label: (toolTipItem: any): string => {
                                    return`${toolTipItem.label}: ${toolTipItem.raw}%`;
                                }
                            }
                        }
                    },
                    onClick: this.purchaseMixOneClicked
                }
            });            
        }

        updateChartOne() {
            // create chart
            this.drawChartOne();

            // double check chart has been created
            if (this.purchaseMixOneChart === undefined) {
                return;
            }

            // update datasets
            this.purchaseMixOneChart.data = this.reportOne.purchaseMix;
            this.purchaseMixOneChart.update();
        }

        purchaseMixOneClicked(_: ChartEvent, elements: ActiveElement[], chart: Chart): void {
            if (!elements.length) return;

            const element = elements[0]

            const dataSet = chart.data.datasets[element.datasetIndex] as any;
            const dropAccount = +dataSet.id;
            const key: string = this.reportOne.purchaseMix.labelValues[element.index];
            const categoryID = +key.split("-")[0];
            const supplierID = +key.split("-")[1];

            if (!Utils.isEmptyId(supplierID)) {
                toastr.warning("We're unable to show purchases for third party suppliers", "Sorry!");
                return;
            }

            const dialogue = this.$refs.purchaseLinesDialogue as PurchasesDialogue;
            dialogue.viewPurchaseLines({
                agreementNumber: this.requestOne.agreementNumber,
                dropAccountNumber: dropAccount,
                date: this.requestOne.monthYear!,
                categoryID: categoryID,
                includeInactive: this.$store.state.includeInactive,
                itemsPerPage: 20,
                pageNumber: 0
            });            
        }

        drawChartTwo() {
            // get canvas dom element 
            const canvas = (this.$refs.purchaseMixTwoChart as HTMLCanvasElement);
            if (canvas === undefined) return;

            // get context from canvas
            const ctx = canvas.getContext("2d");
            if (ctx === undefined) return;

            // clear chart if already exists
            if (this.purchaseMixTwoChart !== undefined) {
                this.purchaseMixTwoChart.destroy();
            }

            // create chart
            this.purchaseMixTwoChart = new Chart(ctx!, {
                type: "doughnut",
                data: {
                    datasets: []
                },
                options: {
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: true,
                            position: "right"
                        },
                        tooltip: {
                            callbacks: {
                                label: (toolTipItem: any): string => {
                                    return`${toolTipItem.label}: ${toolTipItem.raw}%`;
                                }
                            }
                        }
                    },
                    onClick: this.purchaseMixTwoClicked
                }
            });            
        }

        updateChartTwo() {
            // create chart
            this.drawChartTwo();

            // double check chart has been created
            if (this.purchaseMixTwoChart === undefined) {
                return;
            }

            // update datasets
            this.purchaseMixTwoChart.data = this.reportTwo.purchaseMix;
            this.purchaseMixTwoChart.update();
        }

        purchaseMixTwoClicked(_: ChartEvent, elements: ActiveElement[], chart: Chart): void {
            if (!elements.length) return;

            const element = elements[0]

            const dataSet = chart.data.datasets[element.datasetIndex] as any;
            const dropAccount = +dataSet.id;
            const key: string = this.reportTwo.purchaseMix.labelValues[element.index];
            const categoryID = +key.split("-")[0];
            const supplierID = +key.split("-")[1];
           
            if (!Utils.isEmptyId(supplierID)) {
                toastr.warning("We're unable to show purchases for third party suppliers", "Sorry!");
                return;
            }

            const dialogue = this.$refs.purchaseLinesDialogue as PurchasesDialogue;
            dialogue.viewPurchaseLines({
                agreementNumber: this.requestTwo.agreementNumber,
                dropAccountNumber: dropAccount,
                date: this.requestTwo.monthYear!,
                categoryID: categoryID,
                includeInactive: this.$store.state.includeInactive,
                itemsPerPage: 20,
                pageNumber: 0
            });            
        }

    }   
