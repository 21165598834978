export interface IComparisonProduct {

    code: number;
    description: string;
    cases: number;
    splits: number;
    value: number;

}

export class ComparisonProduct implements IComparisonProduct {

    code: number = 0;
    description: string = "";
    cases: number = 0;
    splits: number = 0;
    value: number = 0;

}