import { mapData } from "@/utilities/DataMapping";

export interface IKeyMetrics {
    totalSpend: number;
    nonContractSpend: number;
    contractSpend: number;
    thirdPartySpend: number;
    nonContractPct: number;
    contractPct: number;
    cprpd: number;
    hasThirdPartySuppliers: boolean;
}

export class KeyMetrics implements IKeyMetrics {

    update(data: any) {
        mapData(data, { root: () => this });
    }

    totalSpend: number = 0;
    nonContractSpend: number = 0;
    contractSpend: number = 0;
    thirdPartySpend: number = 0;
    nonContractPct: number = 0;
    contractPct: number = 0;
    cprpd: number = 0;
    hasThirdPartySuppliers: boolean = false;

}