import { mapData } from  "@/utilities/DataMapping";
import { IKeyMetrics, KeyMetrics } from "@/model/KeyMetrics";
import { ChartData } from "@/model/ChartData";
import { ChartDataset } from "@/model/ChartDataset";
import { IComparisonProduct, ComparisonProduct } from "@/model/ComparisonProduct";
import { IComparisonSharedProduct, ComparisonSharedProduct } from "@/model/ComparisonSharedProduct";

export interface IComparisonReport {
    keyMetrics: IKeyMetrics;
    purchaseMix: ChartData<string>;
    uniqueItems: Array<IComparisonProduct>;
    sharedItems: Array<IComparisonSharedProduct>;
}

export class ComparisonReport implements IComparisonReport {

    update(data: any) {
        mapData(data, {
            root: () => this,
            keyMetrics: () => new KeyMetrics(),
            purchaseMix: () => new ChartData<number>(),
            datasets: () => new ChartDataset(),
            uniqueItems: () => new ComparisonProduct(),
            sharedItems: () => new ComparisonSharedProduct()
        });
    }

    keyMetrics: KeyMetrics = new KeyMetrics();
    purchaseMix: ChartData<string> = new ChartData<string>();
    uniqueItems: Array<ComparisonProduct> = [];
    sharedItems: Array<ComparisonSharedProduct> = [];

}